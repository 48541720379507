@import "base";


.box {
  width: 100%;
  padding-left: 10px;
  // padding-right: 10px;
  margin-bottom: 20px;
  max-width: 400px;
  @include lg{
    max-width: 100%;
  }
  justify-content: space-between;

  .portalCard-image {
    margin-bottom: 20px;
    @include sm{
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
    }
   

    img {
      height: 108px;
      @include sm{
        height: 90px;
      }
    }
  }

  .portalCard-text {
    display: flex;
    flex-direction: column;

    .heading {
      @media only screen and (min-width: 991px) and (max-width: 1200px){
        line-height: 19px;
      }
      @include lg{
        height: 60px;
      }
      @include sm{
        margin-bottom: 5px;
        line-height: 30px;
        font-weight: 400;
        font-size: 18px;
        height: 90px;
      }
      
      margin-bottom: 40px;
      font-family: 'Poppins',
        sans-serif;
      font-style: normal;
      font-weight: 550;
      font-size: 20px;
      line-height: 30px;
      height: 90px;
      /* identical to box height, or 150% */
      letter-spacing: -0.015em;

      color: #000000;
    }

    .description {
      font-family: 'Poppins',
        sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
      /* or 167% */

      letter-spacing: -0.015em;

      color: rgba(0, 0, 0, 0.8);
    }

    .learnMore {
      cursor: pointer;
      font-family: 'Poppins',
        sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      /* identical to box height, or 167% */

      letter-spacing: -0.015em;

      /* Actionable Item */

      color: #18A0FB;
    }
  }
}