.loader-div {
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: #FFFFF7;
    z-index: 99;
    height: 100vh;
    position: fixed;
    width: 100%;
    // opacity: 0.5;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.small-loader-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
   

}