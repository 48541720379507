@import "base";


.Register {
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    @include md {
        padding-top: 100px;
        padding-bottom: 20px;
    }
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        content: '';
        background-image: url(../assets/images/login-img.webp);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        @include md {
            display: none;
        }
    }
    .logo {
        position: absolute;
        top: 15px;
        left: 30px;
        cursor: pointer;
        @include md {
            top: 20px;
            left: 0;
            width: 100%;
            text-align: center;
        }
        a {
            display: inline-block;
        }
    }
    .MuiGrid-item {
        @include xl {
            padding-left: 15px !important;
        }
        @include md {
            padding-left: 0 !important;
            width: 100%;
            max-width:100%;
            flex-basis: 100%;
        }
    }
    &_Title {
        font-size: 34px !important;
        font-family: $fontFamilyPop !important;
        font-weight: 700 !important;
        color: $dark_blue;
        margin-bottom: 40px !important;
        @include lg {
            font-size: 28px !important;
            margin-bottom: 30px !important;
        }
        @include md {
            text-align: center;
        }
    }
    .form-group {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            li {
                font-size: $fontSize;
                font-family: $fontFamilyPop;
                font-weight: 400;
                &:last-child {
                    margin-left: auto;
                }
                label {
                    margin: 0;
                    .MuiCheckbox-colorPrimary {
                        padding: 0;
                        margin-right: 10px;
                        color: #55616E;
                        &.Mui-checked {
                            color: $blue;
                        }
                    }
                    .MuiFormControlLabel-label {
                        font-size: $fontSize;
                        font-family: $fontFamilyPop;
                        font-weight: 400;
                    }
                }
                .forgot {
                    color: $text_color;
                    text-decoration: underline;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
        .btn-primary {
            width: 100%;
            margin-top: 15px;
            margin-bottom: 15px;
        }
        p {
            font-size: $fontSize;
            font-family: $fontFamilyPop;
            font-weight: 400;
            color: $text_color;

            @include lg {
                text-align: center;
            }
            a {
                color: $blue;
                font-weight: 600;
                text-decoration: none;
                cursor: pointer;
                &:hover,
                &:focus {
                    color: $dark_blue;
                }
                padding-left: 5px;
            }
            &.terms {
                color: $dark_blue;
                a {
                    color: $dark_blue;
                    text-decoration: underline;
                    font-weight: 400;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
