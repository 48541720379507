@import "base";

/* Add Profile Popup */
.AddProfileModal {
  padding: 15px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > .MuiBackdrop-root {
    background-color: rgba(184, 194, 202, 0.2) !important;
  }

  &_Box {
    position: relative;
    width: 100%;
    max-width: 760px;
    background-color: $white;
    border: 0;
    margin: 0 auto;
    padding: 70px 60px 50px;
    @include border-radius(14px);
    outline: 0;

    @include xxl {
      padding: 50px 30px;
    }

    @include md {
      padding: 40px 15px;
    }

    &--close {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }

    .Title {
      font-size: 28px;
      font-family: $fontFamilyPop;
      font-weight: 700;
      color: $dark_blue;
      margin-bottom: 50px;
      line-height: 48px;

      @include xxl {
        margin-bottom: 30px;
      }

      @include md {
        margin-bottom: 15px;
        font-size: 22px;
        line-height: 33px;
      }
    }

    p {
      font-size: $fontSize;
      font-family: $fontFamilyPop;
      font-weight: 500;
      color: $text_color;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 40px 0 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include md {
        padding-top: 20px;
        flex-wrap: wrap;
      }

      &.terms-popup {
        justify-content: center;
      }

      li {
        padding: 0 10px;

        @include md {
          padding: 5px 0;
          flex: 0 0 100%;
        }

        &:first-child {
          order: 2;

          @include md {
            order: 1;
          }
        }

        &:last-child {
          order: 1;

          @include md {
            order: 2;
          }
        }

        button {
          @include md {
            width: 100%;
          }
        }
      }
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;

      > div {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
      }

      .col-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .col-6 {
        flex: 0 0 50%;
        max-width: 50%;

        @include sm {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }

    .terms-popup {
      max-width: 1030px;
      max-height: 400px;
      overflow: auto;
    }

    &.inputPopup {
      width: auto !important;
    }
  }

  &_Box {
    position: relative;
    width: 100%;
    max-width: 760px;
    background-color: $white;
    border: 0;
    margin: 0 auto;
    padding: 70px 60px 50px;
    @include border-radius(14px);
    outline: 0;

    @include xxl {
      padding: 50px 30px;
    }

    @include md {
      padding: 40px 15px;
    }

    &--close {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }

    .Title {
      font-size: 28px;
      font-family: $fontFamilyPop;
      font-weight: 700;
      color: $dark_blue;
      margin-bottom: 50px;
      line-height: 48px;

      @include xxl {
        margin-bottom: 30px;
      }

      @include md {
        margin-bottom: 15px;
        font-size: 22px;
        line-height: 33px;
      }
    }

    p {
      font-size: $fontSize;
      font-family: $fontFamilyPop;
      font-weight: 500;
      color: $text_color;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 40px 0 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include md {
        padding-top: 20px;
        flex-wrap: wrap;
      }

      &.terms-popup {
        justify-content: center;
      }

      li {
        padding: 0 10px;

        @include md {
          padding: 5px 0;
          flex: 0 0 100%;
        }

        &:first-child {
          order: 2;

          @include md {
            order: 1;
          }
        }

        &:last-child {
          order: 1;

          @include md {
            order: 2;
          }
        }

        button {
          @include md {
            width: 100%;
          }
        }
      }
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;

      > div {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
      }

      .col-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .col-6 {
        flex: 0 0 50%;
        max-width: 50%;

        @include sm {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }

    .terms-popup {
      max-width: 1030px;
      max-height: 400px;
      overflow: auto;
    }

    &.inputPopup {
      width: auto !important;
    }
  }
}

.AddProfileModalPopUp {
  padding: 15px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > .MuiBackdrop-root {
    background-color: rgba(184, 194, 202, 0.2) !important;
  }

  &_Box {
    position: relative;
    width: 100%;
    max-width: 760px;
    background-color: $white;
    border: 0;
    margin: 0 auto;
    padding: 70px 60px 50px;
    @include border-radius(14px);
    outline: 0;

    @include xxl {
      padding: 50px 30px;
    }

    @include md {
      padding: 40px 15px;
    }

    &--close {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }

    .Title {
      font-size: 28px;
      font-family: $fontFamilyPop;
      font-weight: 700;
      color: $dark_blue;
      margin-bottom: 50px;
      line-height: 48px;

      @include xxl {
        margin-bottom: 30px;
      }

      @include md {
        margin-bottom: 15px;
        font-size: 22px;
        line-height: 33px;
      }
    }

    p {
      font-size: $fontSize;
      font-family: $fontFamilyPop;
      font-weight: 500;
      color: $text_color;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 10px 0 0;

      @include md {
        padding-top: 20px;
        flex-wrap: wrap;
      }

      &.terms-popup {
        justify-content: center;

        &.btn {
          text-align: center;
          margin-top: 30px;
        }
      }

      li {
        padding: 0 10px;

        @include md {
          padding: 5px 0;
          flex: 0 0 100%;
        }

        &:first-child {
          order: 2;

          @include md {
            order: 1;
          }
        }

        &:last-child {
          order: 1;

          @include md {
            order: 2;
          }
        }

        button {
          @include md {
            width: 100%;
          }
        }
      }
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;

      > div {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
      }

      .col-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .col-6 {
        flex: 0 0 50%;
        max-width: 50%;

        @include sm {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }

    .terms-popup {
      max-width: 1030px;
      max-height: 400px;
      overflow: auto;
    }
    .termsacceptbtn {
      text-align: center;
      margin-top: 30px;
    }

    &.inputPopup {
      width: auto !important;
    }
  }
}

/* Add Review Modal */
.AddReviewModal {
  padding: 15px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > .MuiBackdrop-root {
    background-color: rgba(184, 194, 202, 0.2) !important;
  }

  &_Box {
    position: relative;
    width: 100%;
    max-width: 604px;
    background-color: $white;
    border: 0;
    margin: 0 auto;
    padding: 70px 60px 50px;
    @include border-radius(14px);
    outline: 0;

    @include xxl {
      padding: 50px 30px;
    }

    @include md {
      padding: 40px 15px;
    }

    &--close {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }

    .Title {
      font-size: 28px;
      font-family: $fontFamilyPop;
      font-weight: 700;
      color: $dark_blue;
      margin-bottom: 20px;
      line-height: 42px;

      @include xxl {
        margin-bottom: 30px;
      }

      @include md {
        margin-bottom: 15px;
        font-size: 22px;
        line-height: 33px;
      }
    }

    p {
      font-size: $fontSize;
      font-family: $fontFamilyPop;
      font-weight: 500;
      color: $grey;
      margin-bottom: 40px;

      .tooltip-icon {
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
          font-size: 18px;
          color: $grey;
          margin-left: 5px;
        }
      }
    }

    .desc {
      margin-bottom: 0;
      font-weight: normal;
      color: $grey !important;
    }

    h4 {
      font-size: 18px;
      font-family: $fontFamilyPop;
      font-weight: 600;
      color: $text_color;
      margin-bottom: 10px;

      small {
        font-size: 14px;
        font-weight: 400;
        color: $grey;
      }
    }

    .MuiLinearProgress-colorPrimary {
      margin-bottom: 34px;
      background-color: $lightest_grey;
      height: 6px;

      .MuiLinearProgress-barColorPrimary {
        background-color: #4bb392;
      }
    }

    .item {
      &:not(:last-child) {
        margin-bottom: 24px;
      }

      p {
        margin-bottom: 6px;
        color: $text_color;

        &.tooltip-icon {
          display: flex;
          align-items: center;
          justify-content: space-between;

          svg {
            font-size: 18px;
            color: $grey;
            margin-left: 5px;
          }
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 40px 0 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include md {
        padding-top: 20px;
        flex-wrap: wrap;
      }

      li {
        padding: 0 10px;

        @include md {
          padding: 5px 0;
          flex: 0 0 100%;
        }

        &:first-child {
          order: 3;

          @include md {
            order: 1;
          }
        }

        &:nth-child(2) {
          order: 2;

          @include md {
            order: 2;
          }
        }

        &:last-child {
          order: 1;
          margin-right: auto;

          @include md {
            order: 3;
            padding-top: 30px;
          }
        }

        button {
          min-width: 130px;

          @include md {
            width: 100%;
          }
        }
      }
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;

      > div {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
      }

      .col-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}

.AddSchoolModal {
  padding: 15px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > .MuiBackdrop-root {
    background-color: rgba(184, 194, 202, 0.2) !important;
  }

  &_Box {
    position: relative;
    width: 100%;
    max-width: 500px;
    background-color: $white;
    border: 0;
    margin: 0 auto;
    padding: 70px 60px 50px;
    @include border-radius(14px);
    outline: 0;

    @include xxl {
      padding: 50px 30px;
    }

    @include md {
      padding: 40px 15px;
    }

    &--close {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }

    .form-group {
      &.col-6 {
        flex: 0 0 50%;
        max-width: 50%;

        @include md {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      .MuiInputBase-colorPrimary {
        border: 0;
        padding: 0;

        > input {
          height: 50px;
          padding: 0 15px;
          font-size: 14px;
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          color: $text_color;
          outline: 0;
          border: 0;
        }

        > .MuiAutocomplete-endAdornment {
          top: 0;

          button {
            height: 50px;
            background-color: transparent;
            outline: 0;
            border: 0;
          }
        }

        fieldset {
          border: 0;
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 40px 0 0;
      display: flex;
      align-items: center;

      // justify-content: flex-end;
      @include md {
        padding-top: 20px;
        flex-wrap: wrap;
      }

      li {
        padding: 0 8px;

        @include md {
          padding: 5px 0;
          flex: 0 0 100%;
        }

        &:first-child {
          order: 2;

          @include md {
            order: 1;
          }
        }

        &:last-child {
          order: 1;

          @include md {
            order: 2;
          }
        }

        button {
          @include md {
            width: 100%;
          }
        }
      }
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;

      > div {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
      }

      .col-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .col-6 {
        flex: 0 0 50%;
        max-width: 50%;

        @include sm {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }
}

.SharePostModal {
  padding: 15px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > .MuiBackdrop-root {
    background-color: rgba(184, 194, 202, 0.2) !important;
  }

  &_Box {
    position: relative;
    width: 100%;
    max-width: 500px;
    background-color: $white;
    border: 0;
    margin: 0 auto;
    padding: 70px 60px 50px;
    @include border-radius(14px);
    outline: 0;

    @include xxl {
      padding: 50px 30px;
    }

    @include md {
      padding: 40px 15px;
    }

    &--close {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }

    .Title {
      font-size: 28px;
      font-family: $fontFamilyPop;
      font-weight: 700;
      color: $dark_blue;
      margin-bottom: 24px;
      line-height: 48px;

      @include md {
        margin-bottom: 15px;
        font-size: 22px;
        line-height: 33px;
      }
    }

    .divshare {
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: center;

      .share-button {
        margin: 10px;

        &.copy {
          height: 30px;
          width: 30px;
          cursor: pointer;
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 40px 0 0;
      display: flex;
      align-items: center;

      // justify-content: flex-end;
      @include md {
        padding-top: 20px;
        flex-wrap: wrap;
      }

      li {
        padding: 0 8px;

        @include md {
          padding: 5px 0;
          flex: 0 0 100%;
        }

        &:first-child {
          order: 2;

          @include md {
            order: 1;
          }
        }

        &:last-child {
          order: 1;

          @include md {
            order: 2;
          }
        }

        button {
          @include md {
            width: 100%;
          }
        }
      }
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;

      > div {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
      }

      .col-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .col-6 {
        flex: 0 0 50%;
        max-width: 50%;

        @include sm {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }
}
.nooptiontext {
  cursor: pointer;
  // color: red;
  // background-color: $blue;
  color: $dark_blue;
  font-weight: bold;
}

/* Delete Popup */
.deleteModal {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: auto;
  &.job {
    background: rgba(0 ,0,0, 0.5)
  }
  > .MuiBackdrop-root {
    background-color: rgba(184, 194, 202, 0.2) !important;
  }
  &_Box {
    position: relative;
    width: 100%;
    max-width: 488px;
    background-color: $white;
    border: 0;
    margin: 0 auto;
    padding: 60px 15px 70px;
    @include border-radius(14px);
    outline: 0;
    @include md {
      padding: 60px 15px 50px;
    }
    &--close {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
    .Title {
      font-size: 28px;
      font-family: $fontFamilyPop;
      font-weight: 700;
      color: $dark_blue;
      margin-bottom: 24px;
      line-height: 48px;
      @include md {
        margin-bottom: 15px;
        font-size: 22px;
        line-height: 33px;
      }
    }
    p {
      font-size: $fontSize;
      font-family: $fontFamilyPop;
      font-weight: 500;
      color: $text_color;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 40px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @include md {
        padding-top: 20px;
        flex-wrap: wrap;
      }
      li {
        padding: 0 10px;
        @include md {
          padding: 5px 0;
          flex: 0 0 100%;
        }
        &:first-child {
          order: 2;
          @include md {
            order: 1;
          }
        }
        &:last-child {
          order: 1;
          @include md {
            order: 2;
          }
        }
        button {
          @include md {
            width: 100%;
          }
        }
      }
    }
  }
  &_EditBox {
    position: relative;
    width: 100%;
    max-width: 488px;
    background-color: $white;
    border: 0;
    margin: 0 auto;
    padding: 50px 10px 60px;
    @include border-radius(14px);
    outline: 0;
    @include md {
      padding: 60px 15px 50px;
    }
    &--close {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
    .ActionButton{
      margin-top: 30px;
    }
    .Title {
      font-size: 28px;
      font-family: $fontFamilyPop;
      font-weight: 700;
      color: $dark_blue;
      margin-bottom: 10px;
      padding-left: 20px;
      @include md {
        margin-bottom: 15px;
        font-size: 22px;
        line-height: 33px;
      }
    }
    p {
      font-size: 14px;
      font-family: $fontFamilyPop;
      font-weight: 500;
      color: $text_color;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0px 0 0;
      display: flex;
      align-items: center;
      @include md {
        padding-top: 20px;
        flex-wrap: wrap;
      }
      li {
        padding: 0 10px;
        @include md {
          padding: 5px 0;
          flex: 0 0 100%;
        }
        &:first-child {
          order: 2;
          @include md {
            order: 1;
          }
        }
        &:last-child {
          order: 1;
          @include md {
            order: 2;
          }
        }
        button {
          @include md {
            width: 100%;
          }
        }
      }
    }
  }
}

.askReviewModal {
  padding: 15px;
  display: block;
  // flex-wrap: wrap;
  // align-items: center;
  text-align: start!important;
  overflow: auto;
  > .MuiBackdrop-root {
    background-color: rgba(184, 194, 202, 0.2) !important;
  }
  &_Box {
    position: relative;
    width: 100%;
    max-width: 600px;
    background-color: $white;
    border: 0;
    margin: 0 auto;
    padding: 60px 15px 70px;
    @include border-radius(14px);
    outline: 0;
    @include md {
      padding: 60px 15px 50px;
    }
    &--close {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
  
    .Title {
      font-size: 28px;
      font-family: $fontFamilyPop;
      font-weight: 700;
      color: $dark_blue;
      margin-bottom: 24px;
      line-height: 48px;
      @include md {
        margin-bottom: 15px;
        font-size: 22px;
        line-height: 33px;
      }
    }
    .modalbody {
      .para1 {
        margin-top: 20px;
      }
      .para2 {
        margin-top: 20px;
      }
      text-align: start;
    p {
      font-size: $fontSize;
      font-family: $fontFamilyPop;
      font-weight: 500;
      color: $text_color;
    }
  }
    ul {
      list-style: none;
      margin: 0;
      padding: 40px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @include md {
        padding-top: 20px;
        flex-wrap: wrap;
      }
      li {
        padding: 0 10px;
        @include md {
          padding: 5px 0;
          flex: 0 0 100%;
        }
        &:first-child {
          order: 2;
          @include md {
            order: 1;
          }
        }
        &:last-child {
          order: 1;
          @include md {
            order: 2;
          }
        }
        button {
          @include md {
            width: 100%;
          }
        }
      }
    }
  }
}

.fileNameWithCross {
  width: 100%;
  display: flex;
  align-items: center;
  .fileName {
    display: flex;
    padding: 5px;
    align-items: center;
    margin-right: 10px;
    border-radius: 10px;
    span {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      max-width: 200px;
      overflow: hidden;
      white-space: nowwrap;
      text-overflow: ellipsis;
    }

    background-color: rgb(197, 189, 189);
  }
  img {
    margin-top: 5px;
    cursor: pointer;
  }
}
