@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Poppins:wght@300;400;500;600;700;800;900&family=Raleway:wght@300;400;500;600;700;800&family=Roboto:wght@400;500;700;900&display=swap");

// Variables
$fontFamilyPop: "Poppins", sans-serif;
$fontSize: 14px;

// Color
$white: #ffffff;
$blue: #2196f3;
$dark_blue: #193d59;
$text_color: #151c21;
$light_grey: #b5c0c9;
$dark_grey: #55616e;
$light_grey: #b5c0c9;
$light_blue: #e5ecff;
$lightest_grey: #dbe5f0;
$popupBg: #b8c2ca;
$grey: #8e979e;

//Media Variable
$sm: 575px;
$md: 767px;
$lg: 991px;
$xl: 1170px;

@mixin sm {
  @media (max-width: #{$sm}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$md}) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$lg}) {
    @content;
  }
}

@mixin xl {
  @media (max-width: #{$xl}) {
    @content;
  }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

.EmployerProfile {
  padding-top: 80px;
  padding-bottom: 100px;
  margin-bottom: 30px;

  @include lg {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  @include md {
    padding-top: 60px;
  }

  .EmployerInfo {
    @include md {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
      margin-bottom: 40px;
    }

    .cover {
      background-color: $white;
      padding: 40px 30px;

      box-shadow: 20px 20px 50px rgba(238, 241, 248, 0.5);
      @include border-radius(16px);

    }

    .titleDiv {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .Title {
        font-weight: bold;
        font-size: 28px;
        color: $dark_blue;
      }

      svg {
        cursor: pointer;
      }
    }

    .info {
      padding-top: 30px;

      .profileimg {
        img {
          object-fit: contain;
          float: left;
          width: 133px;
          height: 133px;
          @include border-radius(50%);
          overflow: hidden;
          padding: 10px;
          box-shadow: 8px 8px 50px rgba(233, 236, 245, 0.5);

          @include lg {
            width: 80px;
            height: 80px;
          }

          img {
            width: 100%;
            height: 100%;
            @include border-radius(50%);
          }
        }
      }

      .text {
        // padding-left: 30px;
        display: block;
        padding-top: 15px;
        padding-left: 15px;
        overflow: hidden;

        @include lg {
          padding-top: 5px;
          padding-left: 10px;
        }


        &_Name {
          font-family: $fontFamilyPop;
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          color: $text_color;

          @include lg {
            font-size: 18px;
          }
        }

        &_id {
          font-size: 14px;
          font-family: $fontFamilyPop;
          font-style: normal;
          color: $grey;
          line-height: 21px;
          display: block;
          margin-bottom: 16px;
        }
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        @include lg {
          padding-top: 0;
        }

        li {
          display: inline-block;
          padding-right: 8px;
          padding-bottom: 8px;

          &:last-child {
            padding-right: 0;
          }

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            @include border-radius(50%);
            background-color: $light_blue;
            color: $text_color;
            transition: 0.5s;

            svg {
              width: 18px;
              height: 18px;
            }

            @include lg {
              img {
                width: 15px;
              }
            }

            &:hover {
              background-color: $light_grey;
            }
          }
        }
      }
    }

    .details {
      display: block;
      width: 100%;
      overflow: hidden;

      .row-data {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 15px;

        @include lg {
          display: block;
          padding-top: 0px;

        }

        .detailsLeft {
          flex-basis: 50%;
          align-items: flex-start;

          display: flex;

          img {
            width: 25px;
            object-fit: contain;
          }

          .text {
            font-size: 14px;
            color: $text_color;
            padding-left: 15px;
            word-break: break-all;
          }
        }

        .detailsRight {
          flex-basis: 50%;
          align-items: flex-start;
          display: flex;

          img {
            width: 25px;
            object-fit: contain;
          }

          .text {
            font-size: 14px;
            color: $text_color;
            padding-left: 15px;
            word-break: break-word;
          }
        }
      }
    }

    .about {
      margin-top: 30px;

      @include md {
        margin-top: 20px;
      }

      .content {
        font-size: 14px;
        color: $text_color;
        font-family: $fontFamilyPop;
      }
    }

    .Text {
      display: block;
      overflow: hidden;
      padding-left: 20px;
      padding-top: 10px;

      @include md {
        width: 100%;
        padding-left: 0;
      }

      &_tags {
        padding-top: 10px;

        &--tag {
          background-color: $light_grey;
          color: $dark_grey;
          margin: 0 10px 10px 0;
          font-size: 12px;
          font-family: $fontFamilyPop;
          font-weight: 500;
          height: 34px;

          span {
            padding: 0 20px;
          }

          &.Number {
            background-color: transparent;
            width: 34px;
            height: 34px;
            @include border-radius(50%);
            border: 1px solid $lightest_grey;
            font-size: 10px;
            font-weight: 600;

            span {
              padding: 0;
            }
          }
        }
      }
      
    }
  }





  

  &_Right {
    @include md {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
  
 
    // .EmployerProfile_Tabs--List {
    //   margin-left: 0;
    // }
    .cover {
      padding: 27px 30px;
      background-color: $white;
      @include border-radius(16px);
      box-shadow: 20px 20px 50px rgba(238, 241, 248, 0.5);

      @include lg {
        padding: 15px;
      }


      >div>.cover {
        margin-left: 0;
        padding: 0;
        background-color: transparent;
        @include border-radius(0);
        box-shadow: none;
      }

      .nopadding {
        padding: 0 !important;
      }
    }

    .postField {
      padding-bottom: 50px;

      @include md {
        padding-bottom: 30px;
      }

      .form-group {
        margin-bottom: 0 !important;

        @include sm {
          display: flex;
          flex-wrap: wrap;
        }

        .over {
          display: block;
          overflow: hidden;
          position: relative;
          width: auto;

          @include sm {
            width: 100%;
            order: 1;
            flex: 0 0 100%;
          }

          .msg {
            font-size: 14px;
            color: $grey;
            float: right;
          }

          .form-control {
            padding-right: 80px;
            min-height: 50px;
            resize: none;

            &.nopadding {
              padding-right: 0;

            }

          }

          .tagsdiv {
            margin-top: 25px;
            height: auto;

          }

          fieldset {
            display: none;
          
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          position: absolute;
          top: 0;
          right: 15px;
          bottom: 0;
          display: flex;
          align-items: center;
          height: 50px;

          li {
            margin-right: 10px;
            display: inline-block;

            &:last-child {
              margin-right: 0;
            }

            label {
              width: 24px;
              height: 24px;
              margin: 0;
              cursor: pointer;
            }
          }
        }

        input[type="file"] {
          width: 0;
        }
      }

      .CustomBtn {
        padding-left: 20px;
        float: right;

        @include sm {
          margin-top: 14px;
          padding-left: 0;
          width: 100%;
          order: 2;
          flex: 0 0 100%;

          button {
            width: 100%;
          }
        }
      }
    }

    .postoverview {
      position: relative;
      margin-top: 10px;

      @include md {
        display: block;
      }

      .image {

        img,
        video {
          object-fit: cover;
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }

      }

      .text {
        position: absolute;
        top: 10px;
        left: 60px;
        display: flex;

        img {
          width: 20px;
        }

        a {
          line-height: 30px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -moz-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
&_Tabs {
  margin-bottom: 50px;
  &--List {

    margin-bottom: 50px;
    margin-left: 20px;
    @include md {
      margin-bottom: 30px;
      margin-left: 0px;
    }
    .MuiTabs-scroller {
      overflow: auto !important;
      .MuiTabs-flexContainer {
        @include md {
          justify-content: flex-start;
        }
      }
    }
    button {
      position: relative;
      padding: 20px 26px;
      font-size: 18px;
      font-family: $fontFamilyPop;
      font-weight: 500;
      color: $dark_blue;
      line-height: 27px;
      z-index: 1;
      text-transform: inherit;
      margin: 0 10px;
      @include md {
        padding: 10px 20px;
        margin: 0;
      }
      &.Mui-selected {
        color: $dark_blue;
      }
      > span {
        display: none;
      }
      @include lg {
        margin: 0;
      }
    }
    .MuiTabs-indicator {
      height: 100%;
      background-color: $light_blue;
      @include border-radius(16px);
    }
  }
}
.table-container {
  margin-top: 50px;

  @include md {
    margin-top: 30px;

  }

  table {
    thead {
      tr {
        background-color: $lightest_grey;

        th {
          font-weight: bold;
          padding: 15px 20px;

        }
      }
    }

    tbody {
      tr {
        td {
          padding: 20px;
          font-family: $fontFamilyPop;

          font-size: 14px;
          color: $text_color;
          font-weight: 500;

          &.link {
            color: $blue;
            cursor: pointer;
            font-weight: 500
          }

          a {
            &.jobName {
              color: $blue;
              cursor: pointer;
              font-weight: 500
            }
          }

          .employer {
            display: flex;
            align-items: center;
            .name {
              font-size: 12px;
              font-family: "Poppins",sans-serif;
              font-weight: 500;
              color: #8e979e;
              line-height: 21px;
              white-space: nowrap;
            }
          }

          .location {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-family: "Poppins",sans-serif;
            font-weight: 500;
            color: #8e979e;
            line-height: 21px;
            white-space: nowrap;


          }
        }
      }
    }
  }
}


.jobs-section,
.moreinfo-section {

  &.Jobs_Item {
    padding: 0;
  }

  @include md {
    padding: 0
  }
  .create-job {
    position: relative;
    text-align: right;
    margin-bottom: 30px;
    // float: right;
    @include sm {
      .CustomBtn {
        position: absolute;
        top: -10px;
        right: 0;
        button {
          min-width: auto;
        }
      }
    }
  }
  .titleDiv {
    display: flex;
    justify-content: space-between;
    // align-items: center;

    .title {
      font-family: $fontFamilyPop;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      color: $dark_blue;
      display: flex;
      align-items: center;

      .jobs {
        font-weight: 600;
        font-size: 16px;
        color: $grey;
        padding-left: 12px;

      }
    }
    @include sm {
      display: block;
    }
  }

  .content {
    padding-top: 30px;
  }

}

.responsive {
  &.in-mobile {
    overflow: hidden;

    .row {
      min-width: auto;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

  }
}
&_Delele {
  .forDesktop {
    font-size: 18px;
    font-family: $fontFamilyPop;
    font-weight: 600;
    cursor: pointer;
    color: $dark_grey;
    text-decoration: none;
    &:hover {
      color: $dark_blue;
    }
    @include md {
      display: none;
    }
  }
  .CustomBtn {
    display: none;
    @include md {
      display: block;
      text-align: center;
    }
    button {
      @include sm {
        width: 100%;
      }
    }
  }
}



}