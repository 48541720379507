@import "base";

.CustomBtn {
    button {
        padding: 0 15px;
        height: 50px;
        border-radius: 14px;
        text-transform: inherit;
        font-family: $fontFamilyPop;
        font-weight: 600;
        font-size: 14px;
        min-width: 180px;
        box-shadow: none;
        text-transform: capitalize;

        &:disabled {
            opacity: 0.5;
            color: $white;
        }

        &.btn-primary {
            line-height: 50px;
            background-color: $blue;
        }

        &.btn-primary2 {
            line-height: 50px;
            background-color: $blue;
            min-width: 310px;
            margin: 10px;

        }

        &.btn-outline-primary {
            line-height: 46px;
            background-color: transparent;
            border: 2px solid $blue;
            color: $blue;

            &:hover,
            &:focus {
                background-color: $blue;
                color: $white;
            }
        }

        &.btn-outline-primary2 {
            line-height: 46px;
            background-color: transparent;
            width: 100%;
            max-width: 310px;
            border: 2px solid $blue;
            color: $blue;

            &:hover,
            &:focus {
                background-color: $blue;
                color: $white;
            }
        }

        &.resourceButton{
            padding: 0 15px;
            height: 35px;
            border-radius: 14px;
            text-transform: inherit;
            font-family: "Poppins", sans-serif;
            font-weight: 600;
            font-size: 12px;
            min-width: 89px;
            box-shadow: none;
        }
        &.item-11-button{
            padding: 0 15px;
            height: 35px;
            border-radius: 14px;
            text-transform: inherit;
            font-family: "Poppins", sans-serif;
            font-weight: 600;
            font-size: 12px;
            min-width: 130px;
            box-shadow: none;
            
           

        }
        &.item-11-button-2{
            padding: 0 15px;
            height: 35px;
            border-radius: 14px;
            text-transform: inherit;
            font-family: "Poppins", sans-serif;
            font-weight: 600;
            font-size: 12px;
            min-width: 130px;
           
            box-shadow: none;
            background-color: transparent;
            border: 2px solid $grey;
            color: $grey;
            &:hover,
            &:focus {
                background-color: $grey;
                color: $white;
            }


        }

        &.btn-outline-secondary {
            line-height: 46px;
            background-color: transparent;
            border: 2px solid $grey;
            color: $grey;

            &:hover,
            &:focus {
                background-color: $grey;
                color: $white;
            }
        }

        &.btn-outline-secondary2 {
            margin: 10px;
            line-height: 46px;
            background-color: transparent;
            border: 2px solid $grey;
            width: 100%;
            min-width: 310px;
            max-width: 310px;
            color: $grey;

            &:hover,
            &:focus {
                background-color: $grey;
                color: $white;
            }
        }
    }
}