
.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
//   height: 30vh;
  background-color: #fff;
  color: #3498db;
  text-align: center;
  padding: 20px;
}

.not-found-title {
  font-size: 8rem;
  margin: 0;
}

.not-found-message {
  font-size: 1.5rem;
  margin: 20px 0;
}

.not-found-link {
  font-size: 1.2rem;
  color: #3498db    ;
  text-decoration: none;
  border: 2px solid #3498db;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #3498db;
    color: #fff;
  }
}
