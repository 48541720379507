@import "base";

.Terms {
    padding-top: 80px;
    padding-bottom: 80px;
    margin: 0 auto;
    max-width: 1200px;

    @include lg {
        padding-top: 70px;
        padding-bottom: 70px;
        padding-left: 15px;
        padding-right: 15px;

    }

    @include md {
        padding-top: 60px;
        padding-bottom: 60px;
    padding-left: 15px;
    padding-right: 15px;

    }

    .container {
        max-width: 766px;
    }

    &.aspopup {
        padding-top: 0;
        padding-bottom: 0;
    }


    &--Back {
        font-size: 18px;
        font-family: $fontFamilyPop;
        font-weight: 600;
        text-decoration: none !important;
        display: flex;
        color: $blue  !important;
        align-items: center;
        margin-bottom: 50px !important;
        cursor: pointer;
    }

    .TermsHeader {
        margin-bottom: 30px;
    }

    &_Title {
        font-size: 34px !important;
        font-family: $fontFamilyPop  !important;
        font-weight: 700 !important;
        color: $dark_blue;

        // margin-bottom: 60px !important;
        @include lg {
            font-size: 28px !important;
        }

        @include md {
            margin-bottom: 50px !important;
        }
    }

    .box {
        margin-bottom: 50px;

        h4,
        h5,
        h6 {
            font-family: $fontFamilyPop;
            color: $dark_blue;
        }

        h4 {
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 30px;
        }

        h5 {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 16px;
        }

        h6 {
            font-size: $fontSize;
            font-weight: 600;
            margin-bottom: 16px;
        }

        p {
            font-size: $fontSize;
            font-family: $fontFamilyPop;
            font-weight: 400;
            margin-bottom: 20px;
            line-height: 21px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            text-decoration: none;
            color: $text_color;

            &:hover {
                color: $blue;
            }
        }

        ul {
            list-style: none;
            margin: 0 0 20px;
            padding: 0;

            li {
                font-size: $fontSize;
                font-family: $fontFamilyPop;
                font-weight: 400;
                line-height: 21px;
            }
        }
    }

}

.backToScroll {
    @include border-radius(14px !important);
    background-color: $blue  !important;
    box-shadow: none !important;
    color: $white  !important;
}