@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Poppins:wght@300;400;500;600;700;800;900&family=Raleway:wght@300;400;500;600;700;800&family=Roboto:wght@400;500;700;900&display=swap");

// Variables
$fontFamilyPop: "Poppins", sans-serif;
$fontSize: 14px;

// Color
$blue: #2196f3;
$dark_blue: #193d59;
$text_color: #151c21;
$lightest_grey: #dbe5f0;
$grey: #8e979e;

//Media Variable
$sm: 575px;
$md: 767px;
$lg: 991px;
$xl: 1170px;

@mixin sm {
  @media (max-width: #{$sm}) {
    @content;
  }
}
@mixin md {
  @media (max-width: #{$md}) {
    @content;
  }
}
@mixin lg {
  @media (max-width: #{$lg}) {
    @content;
  }
}
@mixin xl {
  @media (max-width: #{$xl}) {
    @content;
  }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

.form-group {
  position: relative;
  width: 100%;
  margin-bottom: 20px !important;
  label {
    font-size: $fontSize;
    font-family: $fontFamilyPop;
    font-weight: 500;
    color: $text_color;
    display: block;
    margin-bottom: 6px;
    .MuiCheckbox-colorPrimary {
      padding: 0;
      margin-right: 10px;
      color: #55616e;
      border-radius: 50% !important;
      &.Mui-checked {
        color: $blue;
      }
    }
    .MuiFormControlLabel-label {
      font-size: $fontSize;
      font-family: $fontFamilyPop;
      font-weight: 400;
      color: $text_color;
    }
  }

  &.toolTip-icon {
    .icon {
      display: inline-flex;
      svg {
        font-size: 18px;
        margin-left: 5px;
      }
    }
  }
  .react-date-picker {
    &__inputGroup {
      &__input {
        outline: 0;
        // -webkit-appearance: none;
      }
    }
  }

  .text-danger {
    color: red !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }
}
.form-control {
  width: 100%;
  height: 50px;
  background-color: $lightest_grey;
  @include border-radius(14px);
  font-size: $fontSize;
  font-family: $fontFamilyPop;
  font-weight: 400;
  color: $text_color;
  border: 0 ;
  outline: 0;
  padding-left: 15px;
  padding-right: 15px;
  border: 2px solid transparent ;
  box-shadow: none;
  @include sm {
    font-size: 16px;
  }
  &:focus {
    border-color: #b5c0c9;
    background-color: transparent;
  }
  &.custom-select {
    -webkit-appearance: none;
    background-image: url(../../assets/svg-icon/arrow.svg);
    background-position: 98% 50%;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  &.multiselect {
    height: auto;
    min-height: 50px;
    .MuiChip-filled {
      margin: 5px;
    }
  }
  &.icon {
      padding-left: 50px;
  }
  &.profile {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    &.pl {
    padding-left: 50px;

    }
    input[type="file"] {
      -webkit-appearance: none;
      appearance: none;
      width: 0;
      height: 0;
      visibility: hidden;
      display: none;
    }
    .input-icon {
      // padding-left: 50px;
      img {
        height: 30px;
        width: 30px;
        border-radius: 50%;
      }
    }
  }
  fieldset {
    display: none;
  }
  > .MuiInputBase-colorPrimary {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    .MuiOutlinedInput-input {
      height: 50px;
      padding: 0 0 0 15px !important;
    }
  }
  &.tags {
    height: auto!important;

  }
}
.over {
  position: relative;
  width: 100%;

  .input-icon {
    padding: 0;
    position: absolute;
    top: 3px;
    bottom: 0;
    left: 10px;
    width: 50px;
    background-color: transparent;
    border: 0;
    outline: 0;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    -webkit-appearance: none;
    height: 45px;
    &.close {
      left: auto;
      right: 10px;
      cursor: pointer;
    }
  
  }
}
textarea.form-control {
  min-height: 106px;
  resize: none;
  padding-top: 12px;
}
.showPassword {
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  background-color: transparent;
  border: 0;
  outline: 0;
  background-image: url(../../assets/svg-icon/eye-hide.svg);
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  display: block;
  -webkit-appearance: none;
  height: 45px;
  &:checked {
    background-image: url(../../assets/svg-icon/eye.svg);
  }
}



.react-date-picker {
  width: 100%;
  &__wrapper {
    width: 100%;
    height: 50px;
    background-color: $lightest_grey;
    @include border-radius(14px);
    font-size: $fontSize;
    font-family: $fontFamilyPop;
    font-weight: 400;
    color: $text_color;
    border: 0;
    outline: 0;
    padding-left: 15px;
    padding-right: 15px;
    border: 2px solid transparent !important;
    // background-image: url(../../assets/svg-icon/calendar-dark.svg);
    background-position: 98% 50%;
    background-repeat: no-repeat;
    &:focus {
      border-color: #b5c0c9 !important;
      background-color: transparent;
    }
    svg {
      max-width: 20px;
    }
  }
  &__inputGroup { 
    
    &__input {
      font-size: $fontSize;
      font-family: $fontFamilyPop;
      font-weight: 400;
      color: $text_color;
      &::placeholder {
      }
    }
  }
}

.radio {
  label {
    margin: 10px 0 0 !important;
    display: flex;
    align-items: center;
    span {
      padding: 0;
      font-size: $fontSize;
      font-family: $fontFamilyPop;
      line-height: 21px;
      &.MuiRadio-colorPrimary {
        margin-right: 10px;
        color: #55616e;
        &.Mui-checked {
          color: $blue;
        }
      }
    }
  }
}
.error {
  color:red;
  margin-bottom: 10px;
}