@import "base";

.topbar {
  padding: 10px 0;
  position: sticky;
  top: 0;
  z-index: 1;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    li {
      font-weight: 600;
      font-size: $fontSize;
      font-family: $fontFamilyPop;
      &:not(:last-child) {
        margin-right: 46px;
        @include md {
          margin-right: 26px;
        }
      }
      a {
        color: #25343a;
        text-decoration: none;
        &.active {
          color: $white;
        }
      }
      &.cta {
        margin-left: auto;
        display: flex;
        align-items: center;
        @include md {
          display: none;
        }
        button {
          box-shadow: none;
        }
        .btnOutline {
          margin-right: 20px;
          button {
            border-color: $white;
            color: $white;
            &:hover {
              background-color: $white;
              color: $blue;
            }
          }
        }
        .btnPrimary {
          button {
            border: 2px solid $white;
            color: $blue;
            background-color: $white;
            &:hover {
              border-color: $white;
              background-color: $blue;
              color: $white;
            }
          }
        }
      }
    }
  }
}
.backGroundWhite {
  background-color: white !important;
}
.header {
  padding: 30px 0;

  + div {
    min-height: calc(100vh - 163px);

    @include md {
      min-height: calc(100vh - 151px);
    }
  }

  .cover {
    position: relative;
    display: flex;
    align-items: center;

    .menuBar {
      position: absolute;
      top: 10px;
      left: 0;
      display: none;
      z-index: 1;

      @include md {
        display: block;
      }
    }

    .logo {
      cursor: pointer;

      @include md {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -40px;
      }
    }

    .Menu {
      padding-left: 60px;

      .closemenuBar {
        position: absolute;
        top: 15px;
        left: 15px;
        display: none;

        @include md {
          display: block;
        }
      }

      @include md {
        position: fixed;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: #f6f8fe;
        z-index: 10;
        transition: 0.5s;
        display: flex;
        align-items: center;
        padding-left: 0;

        &.showMenu {
          left: 0;
        }

        ul {
          width: 100%;
          flex: 0 0 100%;
          max-width: 100%;
          display: block;

          li {
            display: block;
            padding: 10px 0;
            text-align: center;
          }
        }
      }
    }

    .right {
      margin-left: auto;
      // flex: 1;
      display: flex;
      align-items: center;

      @include lg {
        padding-left: 30px;
      }

      .cta {
        margin-left: auto;
        display: none;
        align-items: center;

        @include md {
          flex: 0 0 100%;
          max-width: 100%;
          position: absolute;
          bottom: 0;
          display: flex;
          left: 0;
          padding: 10px;
          justify-content: space-between;
          width: 100%;
        }
        .list_inner {
          border-bottom: 0px;
          color: #18a0fb;
          text-decoration: none;
          font-size: 14px;
          font-weight: 600;
        }

        > div {
          &:not(:last-child) {
            margin-right: 12px;
          }

          button {
            height: 35px;

            @include lg {
              min-width: 120px;
            }
          }
        }
      }

      .lists {
        margin-right: 0;
        // @include lg {
        //   margin-right: 12px;
        // }
        li {
          list-style: none;

          .list_inner {
            border-bottom: 0px;
            color: #18a0fb;
            text-decoration: none;
          }
        }
      }

      > ul {
        li {
          font-size: $fontSize;
          font-weight: 600;
          list-style: none;
        }
      }

      .User {
        margin-left: auto;
        padding: 0;
        background-color: transparent;
        text-transform: inherit;
        font-size: 18px;
        font-family: $fontFamilyPop;
        font-weight: 500;
        color: $text_color;

        &_Name {
          display: flex;
          align-items: center;
          padding-right: 20px;

          @include sm {
            font-size: 0;
            padding-right: 10px;
          }

          svg {
            margin-left: 6px;
          }
        }

        &_Img {
          width: 48px;
          height: 48px;
          background-color: $white;
          @include border-radius(50%);
          overflow: hidden;
          padding: 6px;

          img {
            width: 100%;
            height: 100%;
            @include border-radius(50%);
            object-fit: cover;
          }
        }

        &:focus {
          .User_Name {
            svg {
              // transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      font-size: 18px;
      font-family: $fontFamilyPop;
      font-weight: 500;
      display: inline-block;

      &:not(:last-child) {
        padding-right: 40px;
        @include lg {
          padding-right: 15px;
        }
      }

      span {
        color: $text_color;
        text-decoration: none;
        cursor: pointer;
        transition: 0.5s;
        border-bottom: 2px solid transparent;

        &:hover,
        &.active {
          border-bottom-color: $text_color;
        }
      }
    }
  }

  &.afterLogin {
    border-bottom: 1px solid #dbe5f0;
  }

  &.beforeLogin {
    .cover {
      .logo {
        left: 0;
        margin-left: 0;
      }

      .menuBar {
        right: 0;
        left: auto;
      }

      .right {
        .closemenuBar {
          position: absolute;
          top: 15px;
          right: 15px;
          display: none;

          @include md {
            display: block;
          }
        }

        @include md {
          position: fixed;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          overflow: auto;
          background-color: #f6f8fe;
          z-index: 10;
          transition: 0.5s;
          display: flex;
          align-items: center;
          padding-left: 0;

          &.showMenu {
            left: 0;
          }

          ul {
            width: 100%;
            flex: 0 0 100%;
            max-width: 100%;
            display: block;

            li {
              display: block;
              padding: 10px 0;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.menuBox {
  ul {
    padding: 15px 0;

    li {
      padding: 0 15px;
      font-size: $fontSize;
      font-family: $fontFamilyPop;
      font-weight: 400;
      color: $text_color;
      line-height: 51px;
      transition: 0.5s;

      &:hover {
        background-color: $lightest_grey;
      }

      a {
        font-size: $fontSize;
        font-family: $fontFamilyPop;
        font-weight: 400;
        color: $text_color;
      }
    }
  }
}
