@import "base";
@import './pages/employer/EmployerJobs/employer-jobs.scss';


* {
  box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
  font-size: $fontSize;
  font-family: $fontFamilyPop;
  font-weight: 400;
  color: $text_color;
  background-color: $body_bg;
  margin: 0px;
}

.container {
  width: 100%;
  max-width: 1439px;
  margin: 0 auto !important;
  padding-left: 15px;
  padding-right: 15px;
  @include xxl {
    max-width: 1270px;
  }
}

img {
    max-width: 100%;
    height: auto;
}

h1,h2,h3,h4,h5,h6,p { margin: 0 0 15px;}
.cursor {
    cursor: pointer;
}

.react-date-picker__inputGroup__input .react-date-picker__inputGroup__year {
    width: 100%!important;
}


.line-break {
    white-space: pre-line;
    &.singlepage {
        display: block!important;
      }
}

.nodataarea {
    width: 100%;text-align: center;
}
.nocursor {
    cursor: default!important;
}
.tiktok {
    max-width: 20px;
  }


.react-datepicker__month {

 .react-datepicker__month-text {
    padding: 15px 0!important;
    font-family: $fontFamilyPop;
    margin: 10px!important;
  }
}

.react-datepicker__year  {
    .react-datepicker__year-text {
        padding: 10px 0!important;
        font-family: $fontFamilyPop;
        margin: 5px!important;
    }
}

.carousel-container {
  padding-bottom: 50px;
  .custom-dot-list-style {
    li {
      margin: 0 5px;
      button {
        margin-right: 0;
      }
    }
  }
}





