@import "base";
.box {
              
    padding-left: 15px;
    padding-right: 15px;
    .portalCard_image {
         width: 100%;
         margin-bottom: 20px;

         img {
              display: block;
              width: 80px;
              height: 80px;
              border-radius: 50%;
         }
    }

    .portalCard_text {
         .heading {
              @include sm {
                   height: inherit;
              }
         }

         .description {
             
              margin-bottom: 10px;

              @include lg {
                   font-size: 16px;
              }
         }
    }
}