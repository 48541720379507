@import "base";
.registerModal {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    overflow: auto;
    @include md {
     
    }
    > .MuiBackdrop-root {
        background-color: rgba(184, 194, 202, 0.2) !important;
    }
    &_Box {
        position: relative;
        width: 100%;
        max-width: 759px;
        background-color: $white;
        border: 0;
        margin: 0 auto;
        padding: 70px 40px 50px;
        @include border-radius(14px);
        outline: 0;
        @include md {
            padding: 60px 15px 30px;
        }
        &--close {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
        }
        .Title {
            font-size: 28px;
            font-family: $fontFamilyPop;
            font-weight: 700;
            color: $dark_blue;
            margin-bottom: 40px;
            line-height: 48px;
            @include md {
                margin-bottom: 15px;
                font-size: 22px;
                line-height: 33px;
            }
        }
        &--item {
            @include md {
                width: 100%;
            }
            .cover {
                padding: 30px 15px;
                background-color: $white;
                box-shadow: 20px 20px 50px rgba(238, 241, 248, 0.5);
                @include border-radius(16px);
                @include md {
                    padding: 15px;
                }
                .img {
                    padding-bottom: 20px;
                }
                label {
                    margin: 0;
                    span {
                        padding: 0;
                        &.MuiRadio-colorPrimary {
                            margin-right: 10px;
                            color: #55616E;
                            &.Mui-checked {
                                color: $blue;
                            }
                        }
                    }
                }
            }
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 40px 0 0;
            display: flex;
            align-items: center;
            justify-content: center;
            @include md {
                padding-top: 30px;
                flex-wrap: wrap;
            }
            li {
                padding: 0 10px;
                @include md {
                    padding: 5px 0; 
                    flex: 0 0 100%;
                }
                &:first-child {
                    order: 2;
                    @include md {
                        order: 1;
                        padding-bottom: 15px;
                    }
                }
                &:last-child {
                    order: 1;
                    @include md {
                        order: 2;
                    }
                }
                button {
                    @include md {
                        width: 100%;
                    }
                }
            }
        }
    }
}