@import "base";



// Landing page
.landing {
     min-height: auto !important;
     overflow: hidden;

     // Job
     .job {
          padding: 50px 0;
          text-align: center;
          background-color: $white;

          @include lg {
               padding: 30px 0;
          }

          h5 {
               margin-bottom: 30px;
               font-size: 24px;
               font-weight: 300;
               color: $black;
               font-family: $fontFamilyMont;

               @include lg {
                    font-size: 20px;
               }

               @include sm {
                    font-size: 16px;
               }
          }

          h2 {
               font-size: 38px;
               font-weight: 400;
               margin-bottom: 40px;
               color: rgba($black, 0.8);
               font-family: $fontFamilyMont;

               @include lg {
                    font-size: 32px;
               }

               @include sm {
                    font-size: 26px;
                    margin-bottom: 20px;
               }
          }

          p {
               font-size: 18px;
               font-weight: 400;
               line-height: 30px;
               color: rgba($black, 0.8);
               width: 100%;
               max-width: 896px;
               margin: 0 auto 20px;
               font-family: $fontFamilyMont;

               @include lg {
                    font-size: 16px;
                    margin-bottom: 20px;
               }
          }

          .cta {
               display: flex;
               flex-wrap: wrap;
               justify-content: center;

               .CustomBtn {
                    padding: 5px;

                    button {
                         line-height: 22px;
                         margin: 0;
                    }
               }
          }
     }

     // Our Mission
     .mission {
          padding: 50px 0;
          background-color: #F4F4F4;

          @include lg {
               padding: 30px 0;
          }

          h2 {
               color: $black;
               font-size: 38px;
               font-weight: 400;
               text-align: center;
               margin-bottom: 40px;
               font-family: $fontFamilyMont;

               @include lg {
                    font-size: 32px;
               }

               @include sm {
                    font-size: 26px;
               }

               span {
                    display: block;
                    font-size: 28px;
                    padding-top: 10px;
                    color: rgba($black, 0.8);

                    @include lg {
                         font-size: 24px;
                    }

                    @include sm {
                         font-size: 20px;
                    }
               }
          }

          .mission_box {

               .text {
                    padding-right: 50px;


                    @include lg {
                         padding-right: 0;
                         padding-bottom: 20px;
                    }

                    ul {
                         list-style: none;
                         padding: 0;
                         margin: 0;

                         li {
                              position: relative;
                              padding-left: 30px;
                              &:not(:last-child) {
                                   margin-bottom: 20px;
                              }
                              .no {
                                   position: absolute;
                                   top: 0;
                                   left: 0;
                                   color: $black;
                                   font-size: 20px;
                                   font-weight: 700;
                                   font-family: $fontFamilyMont;
                                   @include lg {
                                        font-size: 18px;
                                   }
                              }
                              h5 {
                                   color: $black;
                                   font-size: 20px;
                                   font-weight: 700;
                                   margin-bottom: 15px;
                                   font-family: $fontFamilyMont;

                                   @include lg {
                                        font-size: 18px;
                                   }
                              }

                              p {
                                   font-size: 16px;
                                   font-weight: 400;
                                   line-height: 30px;
                                   color: rgba($black, 0.8);
                                   font-family: $fontFamilyMont;
                              }
                         }
                    }
                    .CustomBtn {
                         margin-top: 40px;
                         button {
                              padding-left: 50px;
                              padding-right: 50px;
                         }
                    }
               }

               img {
                    width: 100%;
               }
          }
     }

     .joining {
          padding: 50px 0;
          background-color: #F6F8FF;

          @include lg {
               padding: 30px 0 0;
          }

          h2 {
               color: $black;
               font-size: 38px;
               font-weight: 400;
               text-align: center;
               margin-bottom: 50px;
               font-family: $fontFamilyMont;

               @include lg {
                    font-size: 32px;
                    margin-bottom: 30px;
               }

               @include sm {
                    font-size: 26px;
               }

               span {
                    display: block;
                    font-size: 26px;
                    padding-top: 20px;
                    color: rgba($black, 0.8);

                    @include lg {
                         font-size: 22px;
                         padding-top: 10px;
                    }

                    @include sm {
                         font-size: 20px;
                    }
               }
          }

          .row {
               display: flex;
               flex-wrap: wrap;
               margin-left: -30px;
               margin-right: -30px;

               .box {
                    padding-left: 30px;
                    padding-right: 30px;
                    flex: 0 0 33.33%;
                    max-width: 33.33%;

                    @include lg {
                         flex: 0 0 50%;
                         max-width: 50%;
                         margin-bottom: 30px;
                    }
                    @include md {
                         flex: 0 0 100%;
                         max-width: 100%;
                    }

                    h5 {
                         color: $black;
                         font-size: 20px;
                         font-weight: 700;
                         margin-bottom: 15px;
                         font-family: $fontFamilyMont;
                         overflow: hidden;
                         min-height: 48px;
                         @include lg {
                              font-size: 18px;
                              min-height: auto;
                         }
                    }

                    p {
                         font-size: 16px;
                         font-weight: 400;
                         color: ($black, 0.8);
                         line-height: 30px;
                         font-family: $fontFamilyMont;

                         &:not(:last-child) {
                              margin-bottom: 20px;

                              @include sm {
                                   margin-bottom: 10px;
                              }
                         }
                    }
               }
          }
     }

     // Student
     .student {
          padding: 50px 0;
          text-align: center;
          background-color: $white;

          @include lg {
               padding: 30px 0;
          }

          h2 {
               color: $black;
               font-size: 38px;
               font-weight: 400;
               margin-bottom: 40px;
               font-family: $fontFamilyMont;

               @include lg {
                    font-size: 32px;
               }

               @include sm {
                    font-size: 26px;
               }

               span {
                    display: block;
                    font-size: 26px;
                    font-weight: 400;
                    padding-top: 10px;
                    color: rgba($black, 0.8);

                    @include lg {
                         font-size: 22px;
                    }

                    @include sm {
                         font-size: 18px;
                    }
               }
          }

          .row {
               display: flex;
               flex-wrap: wrap;
               margin-left: -15px;
               margin-right: -15px;

               .card_rev {
                    padding-left: 15px;
                    padding-right: 15px;
                    flex: 0 0 33.33%;
                    max-width: 33.33%;
                    margin: 0;

                    @include lg {
                         flex: 0 0 50%;
                         max-width: 50%;
                    }

                    @include md {
                         flex: 0 0 100%;
                         max-width: 100%;
                         margin-bottom: 20px;

                         &:last-child {
                              margin-bottom: 0;
                         }
                    }
               }
          }
          .fullCard {
               max-width: 100%;
               text-align: left;
          }
     }

     .aboutUs {
          padding: 30px 0;
          background-color: $white;
          @include lg {
               padding-top: 50px;
          }
          h2 {
               color: $black;
               font-size: 38px;
               font-weight: 400;
               margin-bottom: 28px;
               font-family: $fontFamilyMont;
               @include lg {
                    font-size: 32px;
               }
               @include sm {
                    font-size: 26px;
               }
          }
          p {
               font-size: 16px;
               font-weight: 400;
               color: $black;
               line-height: 30px;
               font-family: $fontFamilyMont;
               margin-bottom: 18px;
          }
          .mainImg {
               @include lg {
                    display: none;
               }
          }
          .signature {
               img {
                    max-width: 146px;
               }
          }
     }

     .review {
          padding: 0 25px;
          @include lg {
               padding: 0 35px;
          }
          p {
               position: relative;
               &::before,
               &::after {
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    content: '';
                    background-size: 25px 25px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(../../assets/images/qoute-left.png);
               }
               &::before {
                    top: 0;
                    left: -30px;
               }
               &::after {
                    bottom: 0;
                    right: -30px;
                    transform: rotateY(180deg);
               }
          }
     }
}

.carousel_container {
     padding-bottom: 50px;
}

.custom_dot_list_style {
     bottom: 0;
}