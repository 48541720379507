@import "base";

.banner {
     padding-bottom: 30px;
     width: 100%;

     @include lg {
          padding-bottom: 40px;
     }

     @include md {
          padding-top: 50px;
     }


     h2 {
          font-size: 44px;
          font-weight: 400;
          margin-bottom: 40px;
          color: $black;
          font-family: $fontFamilyMont;

          @include lg {
               font-size: 32px;
               margin-bottom: 20px;
          }

          @include sm {
               font-size: 24px;
          }
     }



     h5 {
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          color: $black;
          margin-bottom: 20px;
          font-family: $fontFamilyMont;

          @include sm {
               font-size: 18px;
          }
     }

     p {
          font-size: 16px;
          font-weight: 400;
          line-height: 30px;
          color: rgba(0, 0, 0, 0.8);
          font-family: $fontFamilyMont;
          a {
               text-decoration: none;
               color: $blue;
          }
     }

     .cta {
          width: 100%;
          padding-top: 40px;
          display: flex;
          justify-content: center;

          @include lg {
               padding-top: 20px;
          }

          .btn_join {
               min-width: 150px;
               max-width: 400px;

          }

          .CustomBtn {

               &:not(:last-child) {
                    margin-right: 30px;

                    @include sm {
                         margin-right: 10px;
                    }
               }

               button {
                    height: 55px;

                    @include sm {
                         min-width: 100px;
                    }
               }
          }
     }

     .cta_left {
          width: 100%;
          padding-top: 40px;
          display: flex;
          justify-content: left;

          @include lg {
               padding-top: 20px;
          }

          .btn_join {
               width: 100%;
          }

          .CustomBtn {
               width: 100%;
               max-width: 253px;
               &:not(:last-child) {
                    margin-right: 30px;

                    @include sm {
                         margin-right: 10px;
                    }
               }

               button {
                    height: 55px;

                    @include sm {
                         min-width: 100px;
                    }
               }
          }
     }

     img {
          @include lg {
               margin-top: 20px;
               max-width: 300px;
               display: none;
          }
     }
}