@import "base";


.card_img {
     // max-width: 200px;
     width: 100%;

     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     text-align: center;
     margin-bottom: 20px;
     .image-box{
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
               width: 80px;
               margin-bottom: 10px;
          }
     }


     .partner{
          width: 250px;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 30px;
          letter-spacing: -0.015em;
          color: $black;
          margin-bottom: 10px;
     }
     

}

.card_rev{
     width: 100%;
     max-width: 800px;
     margin: 0 auto 20px;
     margin-bottom: 20px;
     
     @include sm {
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding-left: 15px;
     }
     // @media only screen and (min-width:501px) and (max-width: 690px) {
     //      width: 500px;
     // }
     @media only screen and (max-width: 690px) {
          padding-left: 0px;
          width: 100%;

     }
     .review {
         
         
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 30px;
          letter-spacing: -0.015em;
          color: $black;
          margin-bottom: 21px;
     }
     .reviews {
          
          font-family: "Poppins", sans-serif;
          font-style: italic;
          font-weight: 300;
          font-size: 15px;
          line-height: 28px;
          color: $black;
     }
}