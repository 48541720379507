@import "base";

// Footer
.footer {
    padding: 50px 0 30px;
    background-color: $white;

    .contact {
        h2 {
            font-size: 28px;
            font-family: $fontFamilyPop;
            font-weight: 700;
            color: $dark_blue;
            line-height: 42px;
            margin-bottom: 10px;

            @include lg {
                font-size: 22px;
            }
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            list-style-type: none;
            padding: 0;
            margin: 0 0 30px;

            li {
                padding: 0 20px;

                @include lg {
                    padding: 0 10px;
                }
            }
        }

        p {
            font-size: $fontSize;
            font-family: $fontFamilyPop;
            font-weight: 400;
            line-height: 21px;
            margin-bottom: 10px;
        }

        a {
            font-size: $fontSize;
            font-family: $fontFamilyPop;
            font-weight: 600;
            color: $text_color;
            text-decoration: none;
        }
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        >div {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            width: 100%;

            @include md {
                flex: 0 0 50%;
                max-width: 50%;

                &:nth-child(1) {
                    order: 2;
                }

                &:nth-child(2) {
                    order: 1;
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin-bottom: 20px;
                }

                &:nth-child(3) {
                    order: 3;
                }
            }
        }
    }

    .footer_terms {
        // background-color: #dbe5f0;
        padding-top: 60px;
        text-align: center;

        @include md {
            padding-top: 30px;
        }

        .terms {
            margin-bottom: 20px;

            a {
                font-weight: bold;
                color: #1976d2 !important;

                &:first-child {
                    margin-right: 51px;
                }
            }

        }
    }

    .copyright {
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        color: #55616E;
        margin: 0;
    }

    .footerLogo {}

    .footerRight {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .CandC {
            width: 250px;
            justify-content: space-between;

            @include sm {
                display: flex;
                flex-direction: column;
            }

            .Community {
                padding: 10px;

                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 24px;
                /* identical to box height, or 160% */

                letter-spacing: -0.015em;
                cursor: pointer;
                color: #000000;
            }

            .Company {
                padding: 10px;
                padding-left: 40px;

                @include sm {
                    padding-left: 10px;
                }

                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 24px;
                /* identical to box height, or 160% */
                cursor: pointer;
                letter-spacing: -0.015em;

                color: #000000;

            }
        }

        .cta {
            .CustomBtn:not(:last-child) {
                margin-bottom: 15px;
            }

            .list_inner {
                border-bottom: 0px;
                color: black;
                text-decoration: none;
                font-size: 14px;
                font-weight: 600;

            }
        }
    }
}