@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Poppins:wght@300;400;500;600;700;800;900&family=Raleway:wght@300;400;500;600;700;800&family=Roboto:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

// Variables
$fontFamilyPop: 'Poppins', sans-serif;
$fontFamilyMont: 'Montserrat', sans-serif;
$fontSize: 14px;

// Color
$white: #ffffff;
$blue: #2196F3;
$grey: #8E979E;
$black: #000000;
$text_color: #151C21;
$dark_blue: #193D59;
$dark_grey: #55616E;
$light_grey: #B5C0C9;
$light_blue: #E5ECFF;
$lightest_grey: #DBE5F0;
$popupBg: #B8C2CA;
$body_bg: #f6f8fe;
$green: #4bb392;



//Media Variable
$xs: 320px;
$sm: 575px;
$md: 767px;
$lg: 991px;
$xl: 1170px;
$xxl: 1460px;


@mixin xs {
     @media (max-width: #{$xs}) {
          @content;
     }
}

@mixin sm {
     @media (max-width: #{$sm}) {
          @content;
     }
}

@mixin md {
     @media (max-width: #{$md}) {
          @content;
     }
}

@mixin lg {
     @media (max-width: #{$lg}) {
          @content;
     }
}

@mixin xl {
     @media (max-width: #{$xl}) {
          @content;
     }
}

@mixin xxl {
     @media (max-width: #{$xxl}) {
          @content;
     }
}

@mixin border-radius($radius) {
     -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
     -ms-border-radius: $radius;
     border-radius: $radius;
}

